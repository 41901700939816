<template>
  <div class="row">
    <div class="col-md-12">
      <vue-dropzone
        :options="dropzoneOptions"
        :useCustomSlot="true"
        :id="vid"
        ref="dropzoneUpload"
        v-on:vdropzone-sending="sendingFile"
        v-on:vdropzone-removed-file="onFileRemoved"
        v-on:vdropzone-complete="onComplete"
        v-on:vdropzone-processing="onFileProcessing"
      >
        <div class="dropzone-custom-content">
          <h3 class="dropzone-custom-title">
            Drag & Drop or Click To Choose A File To Upload.
          </h3>
          <div class="subtitle">Up to 5MB vcard, jpg, png, or gif</div>
        </div>
      </vue-dropzone>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  name: "dropzone-upload",
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      medias: [],
      processing: false,
      dropzoneOptions: {
        maxFilesize: 5,
        maxFiles: 10,
        url: `${process.env.VUE_APP_BASE_API}api/v1/chats/media/upload`,
        thumbnailWidth: 200,
        addRemoveLinks: true,
        acceptedFiles:
          "image/jpeg, image/gif, image/png, audio/basic, audio/L24, audio/mp4, audio/mpeg ,audio/ogg, audio/vorbis, audio/vnd.rn-realaudio, audio/vnd.wave, audio/3gpp, audio/3gpp2, audio/ac3, audio/vnd.wave, audio/webm, audio/amr-nb, audio/amr, video/mpeg, video/mp4, video/quicktime, video/webm, video/3gpp, video/3gpp2, video/3gpp-tt, video/H261, video/H263, video/H263-1998, video/H263-2000, video/H264, image/jpeg, image/gif, image/png, image/bmp, text/vcard, text/csv, text/rtf, text/richtext, text/calendar, text/directory, application/pdf",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      },
    };
  },
  mounted () {
    if (this.images) {
      this.manuallyAddFile(this.images)
      this.medias = JSON.parse(JSON.stringify(this.images))
    }
  },
  watch: {
    processing: {
      handler: function(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$emit("processing", this.processing);
        }
      },
    },
  },
  computed: {
    vid() {
      return Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(2, 10);
    },
  },
  methods: {
    sendingFile() {},

    manuallyAddFile(files) {
      files.forEach((file) => {
        var url = file.path;
        this.$refs.dropzoneUpload.manuallyAddFile(file, url);
      });
      this.medias = files;
      this.$emit("updated", { medias: this.medias });
    },

    removeAllFiles() {
      this.$refs.dropzoneUpload.removeAllFiles();
      this.medias = [];
      this.$emit("updated", { medias: this.medias });
    },

    onFileRemoved(file) {
      if (file.id) {
        this.medias = this.medias.filter((item) => item.id !== file.id);
      } else {
        if (file && file.uuid) {
          this.medias = this.medias.filter(
            (item) => item.uuid !== file.uuid
          );
        }
      }

      this.$emit("updated", { medias: this.medias });
    },

    onComplete(res) {
      if (res.status === "success" || res.manuallyAdded) {
        if (res.manuallyAdded) {
          this.medias.push(res);
        } else {
          const data = JSON.parse(res.xhr.response)["data"]["file"];
          data["uuid"] = res.upload.uuid;
          this.medias.push(data);
        }

        this.$emit("updated", { medias: this.medias });
        this.processing = false;
      } else if(res.status === "error"){
        Vue.$toast.open({
          message: "Maximum file size to upload is 5MB (5120 KB). Try to reduce its resolution to make it under 5MB.",
          type: "error",
        });
      }
    },

    onFileProcessing() {
      this.processing = true;
    },
  },
};
</script>

<style lang="scss"></style>
