<template>
  <div class="row">
    <div class="col-md-12 pt-1" v-if="convert && errors.length">
      <span class="d-block text-danger text-center font-weight-bold">{{ errorMsg }}</span>
    </div>
    <div v-if="label" class="col-md-12">
      <label class="lelabel custom-label">
        {{ label }}
        <a v-if="tooltip" v-b-tooltip.hover :title="tooltip" class="fa fa-question-circle tooltip-icon" />
      </label>
    </div>
    <div class="col-md-12">
      <div class="textarea-emoji-picker" :class="{
        active: active,
        'has-hover': hasHoverEffect,
        'error': convert && errors.length
      }">
        <textarea name="newMessage" class="newMessage" v-model="newMessage" placeholder="Your Message..." :rows="rows"
          ref="newMessage" @focus="handleFocus()" @blur="handleBlur()" :maxlength="maxLength" @keydown.enter="handleEnterKeydown"
          :disabled="disabled"></textarea>
        <div class="message-compose-wrapper border-top d-flex justify-content-space-between align-items-center">
          <div>
            <ul class="icon-ul">
              <li v-if="canUseAi">
                <span class="emoji-trigger icon stroke ai-content-icon" v-b-tooltip.hover title="AI Content"
                  @click="() => isShowAIContentModal = true"
                >
                  <i class="fa fa-magic"></i>
                </span>
                <AIContentModal
                  :open="isShowAIContentModal"
                  :contact="contact"
                  :auto-generate="autoGenerate"
                  @closed="() => isShowAIContentModal = false"
                  @done="(data) => onAddAIContent(data)"
                ></AIContentModal>
              </li>
              <li v-if="enabledMergeField">
                <MergeFields :autoHide="true" :showArrow="false" placement="bottom" popoverClass="more-custom-popover"
                  :hideContactDetail="hideContactMergeFields" @onAdd="onAddMergeField" ref="mergeFieldObj">
                  <template slot="trigger">
                    <span v-b-tooltip.hover class="emoji-trigger icon stroke merge-filed-icon" title="Add Merge Fields">
                      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <title />
                        <g data-name="Contact Info" id="Contact_Info">
                          <g data-name="&lt;Group&gt;" id="_Group_">
                            <g data-name="&lt;Group&gt;" id="_Group_2">
                              <path
                                d="M22.5,6.5V21A1.5,1.5,0,0,1,21,22.5H3A1.5,1.5,0,0,1,1.5,21V3A1.5,1.5,0,0,1,3,1.5H17.5Z"
                                data-name="&lt;Path&gt;" id="_Path_" class="path-style" />
                              <path d="M17.5,1.5V5A1.5,1.5,0,0,0,19,6.5h3.5" data-name="&lt;Path&gt;" id="_Path_2"
                                class="path-style" />
                            </g>
                            <circle cx="12" cy="8.5" data-name="&lt;Path&gt;" id="_Path_3" r="3" class="path-style" />
                            <path
                              d="M12,13.5a11.52,11.52,0,0,0-5.14,1.37A2.51,2.51,0,0,0,5.5,17.11v.46a.93.93,0,0,0,.93.94H17.57a.93.93,0,0,0,.93-.94v-.46a2.51,2.51,0,0,0-1.36-2.23A11.52,11.52,0,0,0,12,13.5Z"
                              data-name="&lt;Path&gt;" id="_Path_4" class="path-style" />
                          </g>
                        </g>
                      </svg>
                    </span>
                  </template>
                </MergeFields>
              </li>
              <li v-if="enabledMms">
                <span v-if="medias && medias.length" class="badge badge-primary">{{ medias.length }}</span>
                <span v-b-tooltip.hover :title="medias && medias.length
                    ? `Has Files Uploaded`
                    : `Upload File`
                  " class="emoji-trigger icon stroke img-icon" :class="{ 'has-medias': medias && medias.length }"
                  @click="onOpenImageModal">
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <title />
                    <path class="path-style"
                      d="M23.5,1.5a1,1,0,0,0-1-1H4.5a1,1,0,0,0-1,1v18a1,1,0,0,0,1,1h18a1,1,0,0,0,1-1Z" />
                    <path class="path-style"
                      d="M21,16.5,18.343,9.411a.5.5,0,0,0-.884-.1L14,14.5l-2.049-2.459a.5.5,0,0,0-.813.063L8.5,16.5" />
                    <circle class="path-style" cx="10" cy="5.997" r="1.5" />
                    <line class="path-style" x1="23.5" x2="3.5" y1="16.497" y2="16.497" />
                    <path class="path-style" d="M3.5,4.5h-2a1,1,0,0,0-1,1v17a1,1,0,0,0,1,1h17a1,1,0,0,0,1-1v-2" />
                  </svg>
                </span>
              </li>
              <li v-if="enabledTemplate">
                <span v-b-tooltip.hover title="Select Template" class="emoji-trigger icon stroke template-icon"
                  @click="onOpenModalTemplate">
                  <svg enable-background="new 0 0 21 21" height="21px" id="Layer_1" version="1.1" viewBox="0 0 21 21"
                    width="21px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="icon">
                      <path
                        d="M4.5,4.62V2.3c0,-0.44,0.36,-0.8,0.8,-0.8h10.203c0.318,0,0.623,0.126,0.849,0.351l2.797,2.797c0.225,0.225,0.351,0.53,0.351,0.849V18.7c0,0.44,-0.36,0.8,-0.8,0.8H5.3c-0.44,0,-0.8,-0.36,-0.8,-0.8v-0.34"
                        fill="none" class="path-style" />
                      <path d="M15.5,3v1.7c0,0.442,0.358,0.8,0.8,0.8h1.7" fill="none" class="path-style" />
                      <path d="M6.7,4.5h6.8M13.5,6.5h0.5M13.5,8.5h4M13.5,10.5h4M13.5,12.5h4M13.5,14.5h4M13.5,16.5h4"
                        fill="none" class="path-style" />
                      <rect fill="none" height="10" class="path-style" width="10" x="1.5" y="6.5" />
                      <polyline fill="none" points="3.5,9.48 3.5,8.5 9.5,8.5 9.5,9.48" class="path-style" />
                      <line fill="none" class="path-style" x1="6.5" x2="6.5" y1="14.5" y2="8.5" />
                      <line fill="none" class="path-style" x1="6" x2="6.98" y1="14.5" y2="14.5" />
                    </g>
                  </svg>
                </span>
              </li>
              <li v-if="enabledSaveReply">
                <span v-b-tooltip.hover title="Save Reply" class="emoji-trigger icon stroke save-reply-icon"
                  @click="onOpenModalSaveReply">
                  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                    <title />
                    <g>
                      <path
                        d="M41.71,14.29l-8-8A1,1,0,0,0,33,6H11a5,5,0,0,0-5,5V37a5,5,0,0,0,5,5H37a5,5,0,0,0,5-5V15A1,1,0,0,0,41.71,14.29ZM28,8v6H14V8ZM14,40V24H34V40Zm26-3a3,3,0,0,1-3,3H36V23a1,1,0,0,0-1-1H13a1,1,0,0,0-1,1V40H11a3,3,0,0,1-3-3V11a3,3,0,0,1,3-3h1v7a1,1,0,0,0,1,1H29a1,1,0,0,0,1-1V8h2.59L40,15.41Z"
                        fill="#333" />
                      <path d="M29,28H19a1,1,0,0,0,0,2H29a1,1,0,0,0,0-2Z" fill="#333" />
                      <path d="M29,34H19a1,1,0,0,0,0,2H29a1,1,0,0,0,0-2Z" fill="#333" />
                    </g>
                  </svg>
                </span>
              </li>
              <li v-if="enabledEmoji">
                <emoji-picker :data="data" @emoji:picked="handleEmojiPicked" />
              </li>
              <li v-if="enableLink">
                <span v-b-tooltip.hover title="ShortLink" class="emoji-trigger icon stroke save-reply-icon"
                  @click="handleOpenModalShortLink">
                  <i class="fa fa-link"></i>
                  <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"/></svg> -->
                </span>
              </li>
            </ul>
          </div>
          <div class="d-flex align-items-center">
            <div v-if="enableIcs">
              <span v-b-tooltip.hover title="Add Calender File" class="emoji-trigger icon schedule-icon"
                style="font-size: 21px;margin-right: 10px;color: grey;" @click="onSendICS">
                <i class="fa fa-calendar"></i>
              </span>
            </div>
            <div v-if="enabledSchedule">
              <span v-b-tooltip.hover :title="scheduleActive ? 'Message Scheduled' : 'Schedule for Later'"
                class="emoji-trigger icon schedule-icon" :class="{ 'active': scheduleActive, 'stroke': !scheduleActive }"
                @click="onScheduleClick">
                <svg id="Layer_1" style="enable-background:new 0 0 64 64;" version="1.1" viewBox="0 0 64 64"
                  xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <path class="st0"
                    d="M50,11H10c-1.1,0-2,0.9-2,2v36c0,1.1,0.9,2,2,2h40c1.1,0,2-0.9,2-2V13C52,11.9,51.1,11,50,11z" />
                  <rect class="st1" height="8" width="8" x="15" y="27" />
                  <rect class="st1" height="8" width="8" x="26" y="27" />
                  <rect class="st1" height="8" width="8" x="37" y="27" />
                  <path class="st2" d="M50,11H10c-1.1,0-2,0.9-2,2v8h44v-8C52,11.9,51.1,11,50,11z" />
                  <rect class="st1" height="8" width="8" x="15" y="38" />
                  <rect class="st1" height="8" width="8" x="26" y="38" />
                  <path class="st3" d="M21,16h-3V9.5C18,8.7,18.7,8,19.5,8h0C20.3,8,21,8.7,21,9.5V16z" />
                  <path class="st3" d="M42,16h-3V9.5C39,8.7,39.7,8,40.5,8l0,0C41.3,8,42,8.7,42,9.5V16z" />
                  <polyline class="st4" points="29.2,30.8 31,32.7 34,29.7 " />
                  <g>
                    <circle class="st3" cx="45.5" cy="46.5" r="11.5" />
                  </g>
                  <line class="st5" x1="45.5" x2="53" y1="46.5" y2="46.5" />
                  <line class="st6" x1="43.6" x2="45.5" y1="41.8" y2="46.5" />
                </svg>
              </span>
            </div>
            <div class="text-left mr-2">
              <SMSLength :text="newMessage" :opt-text="optText" :has-image="hasImage" />
            </div>
            <button v-if="enableTest" :disabled="disabled || loading || !canMessage"
              class="btn btn-sm btn-primary send-btn pull-right mr-1" @click="handleOpenTest" type="button">
              <span>Test <i class="fa fa-check"></i></span>
            </button>
            <button v-if="showSendButton" :disabled="disabled || loading || !canMessage"
              class="btn btn-sm btn-primary send-btn pull-right" @click="send" type="button">
              <atom-spinner v-if="loading" slot="loading" :animation-duration="1500" :size="14" color="#FFF" />
              <span v-else>{{ buttonLabel }} <i class="fa fa-send"></i></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <vuestic-modal v-if="enabledMms" :isOpen="isOpenModalImage" @cancel="closeModalImage" :cancelShown="false"
      :okShown="false" :large="true">
      <span slot="title">Upload Files</span>
      <div class="">
        <b-tabs v-model="activeMediaTabIndex" small card class="image-tabs">
          <b-tab title="New File" active>
            <DropzoneUpload @updated="dropzoneUpdated" ref="dropzoneUpload" :images="images">
            </DropzoneUpload>
          </b-tab>
          <b-tab v-if="enabledGallery" title="Gallery">
            <ImageGallery v-if="activeMediaTabIndex === 1" @imageSelected="onImageSelected" ref="imageGallery">
            </ImageGallery>
          </b-tab>
        </b-tabs>
      </div>
      <div slot="footer" v-if="medias && medias.length">
        <button class="btn btn-danger" @click="removeFiles" type="button">
          <span>Remove Files</span>
        </button>
        <button class="btn btn-primary" @click="closeModalImage" type="button">
          <span>Okay</span>
        </button>
      </div>
    </vuestic-modal>

    <vuestic-modal v-if="enableTest" :isOpen="isShowTestModal" @ok="handleTest" @cancel="isShowTestModal = false"
      okText="Send" cancelText="Cancel" :closeOnOk="false" :processing="isLoadingTest" okClass="btn btn-primary"
      :okDisabled="!testCredential">
      <span slot="title" class="text-primary font-weight-bold">Test</span>

      <p class="font-weight-bold">Please Input Phone Number to Send Test SMS.</p>
      <p>Opt-out message is only appened to the very first message sent out from our system.</p>
      <phone-number-input name="Phone Number" v-model="testCredential" label="Phone Number" />
    </vuestic-modal>

    <vuestic-modal v-if="enabledTemplate" :isOpen="isOpenModalTemplate" @cancel="closeModalTemplate" :cancelShown="false"
      :okShown="false" :large="true">
      <span slot="title">Message Template</span>
      <div class="row">
        <div class="col-md-12">
          <multiselect v-model="selectedTemplate" :options="templatesList" :multiple="false" :close-on-select="true"
            :allow-empty="false" :preserve-search="true" placeholder="Select Template" label="name" track-by="id"
            :preselect-first="true" :show-labels="false">
          </multiselect>
        </div>
        <div class="col-md-12 mt-4" v-if="selectedTemplate">
          <h6 class="font-weight-bold font-italic">Preview</h6>
          <p>{{ selectedTemplate.message }}</p>
          <div class="d-flex align-items-center" v-if="selectedTemplate.medias.length">
            <img v-for="(media, key) in selectedTemplate.medias" :key="key" :src="media.path" height="70px"
              class="mr-2" />
          </div>
        </div>
      </div>
      <div slot="footer">
        <button class="btn btn-primary" :disabled="!selectedTemplate" @click="onSelectTemplate" type="button">
          <span>Select Template</span>
        </button>
      </div>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenModalShortLink" @cancel="() => isOpenModalShortLink = false" :cancelShown="false"
      :okShown="false" :large="true">
      <span slot="title">Add ShortLink</span>
      <div class="row">
        <div class="col-12">
          <text-input v-model="shortLink.href" label="URL"
            tooltip="We will shorten the URL below and allow you to track link opened by contact." />
        </div>
      </div>
      <div slot="footer">
        <button class="btn btn-primary" :disabled="!shortLink.href" @click="handleAddShortLink" type="button">
          <span>Add</span>
        </button>
      </div>
    </vuestic-modal>
  </div>
</template>

<script>
import ImageGallery from "./ImageGallery";
import SMSLength from "./SMSLength";
import data from "@zaichaopan/emoji-picker/data/emojis.json";
import DropzoneUpload from "./DropzoneUpload";
import MergeFields from "./message/MergeFields";
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import AIContentModal from './AIContentModal'

export default {
  components: {
    SMSLength,
    DropzoneUpload,
    MergeFields,
    ImageGallery, AIContentModal,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    tooltip: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    convert: {
      type: Boolean,
      default: false,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    enabledMergeField: {
      type: Boolean,
      default: true,
    },
    enabledMms: {
      type: Boolean,
      default: false,
    },
    enabledGallery: {
      type: Boolean,
      default: true,
    },
    enabledTemplate: {
      type: Boolean,
      default: false,
    },
    enabledSaveReply: {
      type: Boolean,
      default: false,
    },
    enabledEmoji: {
      type: Boolean,
      default: true,
    },
    maxLength: {
      type: Number,
      default: 1600,
    },
    rows: {
      type: Number,
      default: 3,
    },
    showSendButton: {
      type: Boolean,
      default: true,
    },
    hasHoverEffect: {
      type: Boolean,
      default: true,
    },
    optText: {
      type: String,
      default: "",
    },
    contact: {
      type: Object,
      default() {
        return {};
      },
    },
    scheduleActive: {
      type: Boolean,
      default: false,
    },
    enabledSchedule: {
      type: Boolean,
      default: false,
    },
    images: {
      type: Array,
      default: () => {
        return []
      },
    },
    enableIcs: {
      type: Boolean,
      default: false,
    },
    hideContactMergeFields: {
      type: Boolean,
      default: false,
    },
    enableLink: {
      type: Boolean,
      default: false,
    },
    enableTest: {
      type: Boolean,
      default: false,
    },
    selectedInstall: {
      type: Number,
      default: undefined,
    },
    enableAi: {
      type: Boolean,
      default: false,
    },
    autoGenerate: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      mergeFields: [],
      active: false,
      data: data,
      isOpenModalImage: false,
      isOpenModalTemplate: false,
      selectedTemplate: null,
      templates: [],
      shortLink: {},
      dropzoneOptions: {
        maxFilesize: 5,
        maxFiles: 10,
        url: `${process.env.VUE_APP_BASE_API}api/v1/chats/media/upload`,
        thumbnailWidth: 200,
        addRemoveLinks: true,
        acceptedFiles:
          "image/jpeg, image/gif, image/png, audio/basic, audio/L24, audio/mp4, audio/mpeg ,audio/ogg, audio/vorbis, audio/vnd.rn-realaudio, audio/vnd.wave, audio/3gpp, audio/3gpp2, audio/ac3, audio/vnd.wave, audio/webm, audio/amr-nb, audio/amr, video/mpeg, video/mp4, video/quicktime, video/webm, video/3gpp, video/3gpp2, video/3gpp-tt, video/H261, video/H263, video/H263-1998, video/H263-2000, video/H264, image/jpeg, image/gif, image/png, image/bmp, text/vcard, text/csv, text/rtf, text/richtext, text/calendar, text/directory, application/pdf",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      },
      medias: [],
      activeMediaTabIndex: 0,
      isOpenModalShortLink: false,
      isShowTestModal: false,
      testCredential: null,
      isLoadingTest: false,
      isShowAIContentModal: false,
    };
  },

  watch: {
    errors: {
      handler: function (newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          if (newValue.find(item => item.type === 'contact')) {
            this.openContactInfo()
          }
        }
      },
    },
    contact: {
      handler: function () {
        this.initMergeFields();
        this.refreshMessage(this.newMessage);
      },
    },
    businessOwner: {
      handler: function (newValue) {
        if (newValue) {
          this.initMergeFields();
          this.refreshMessage(this.newMessage);
        }
      },
    },
    value: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.refreshMessage(newValue)
        }
      }
    },
    activeMediaTabIndex: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.removeFiles()
        }
      }
    }
  },

  mounted() {
    if (this.$refs.newMessage) this.$refs.newMessage.focus();
    this.loadTemplates();
    this.initMergeFields()
    this.medias = JSON.parse(JSON.stringify(this.images))
  },

  computed: {
    contactFields() {
      return this.$store.getters["auth/contactFields"];
    },

    hasImage() {
      return this.images && !!this.images.length
    },

    buttonLabel() {
      return this.scheduleActive ? 'Schedule Message' : 'Send Message'
    },
    errors() {
      return this.mergeFields.filter(item => {
        return this.newMessage && this.newMessage.includes(item.key)
      })
    },

    errorMsg() {
      return this.errors.map(item => `${item.type} ${item.key}`).join(', ') + ' are missing.'
    },

    business() {
      return this.$store.getters["auth/business"];
    },

    businessOwner() {
      return this.$store.getters["auth/businessOwner"];
    },

    aiEnabled() {
      return this.$store.getters["auth/aiEnabled"];
    },

    canUseAi() {
      return this.aiEnabled && this.enableAi
    },

    newMessage: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },

    iValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },

    canMessage() {
      return (
        (this.value && this.value.length) || (this.medias && this.medias.length)
      ) && !this.errors.length;
    },

    templatesList() {
      return this.templates.map((item) => item);
    },

    vid() {
      return Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(2, 10);
    },
  },

  methods: {
    onAddAIContent({subject, content}) {
      this.newMessage = content.replaceAll('\n', '<br/>')
      this.isShowAIContentModal = false
    },
    onImageSelected(files) {
      this.medias = files
    },

    async onScheduleClick() {
      let hasBadword = await this.checkBadword()
      if (!hasBadword) {
        this.$emit("onSchedule", {
        });
      }
    },
    
    openContactInfo() {
      this.$emit('onOpenContactInfo')
    },

    getValueFromMergeField(key) {
      const result = this.mergeFields.find((item) => item.key == key)
      return result && result.value ? result.value : ''
    },

    refreshMessage(msg) {
      let tmp = msg;
      if (this.convert) {
        this.mergeFields.forEach((item) => {
          if (item.value) {
            tmp = tmp.replaceAll(item.key, item.value)
          }
        });
      }
      this.newMessage = tmp
    },
    initMergeFields() {
      if (this.businessOwner) {
        const { properties } = this.businessOwner;
        const { first_name, last_name, number, email, bday_str, anniv_str, city, note, custom_fields } = this.contact;

        let myPhoneNumber = ''
        let phoneNumber = ''
        if (this.businessOwner.phone) {
          try {
            myPhoneNumber = parsePhoneNumberFromString(this.businessOwner.phone);
            myPhoneNumber = myPhoneNumber.formatNational()
          } catch (error) { }
        }
        if (number) {
          try {
            phoneNumber = parsePhoneNumberFromString(number);
            phoneNumber = phoneNumber.formatNational()
          } catch (error) { }
        }

        this.mergeFields = [
          { key: '[my_first_name]', type: 'my', value: properties.firstName },
          { key: '[my_last_name]', type: 'my', value: properties.lastName },
          { key: '[my_phone_number]', type: 'my', value: myPhoneNumber },
          { key: '[my_email]', type: 'my', value: this.businessOwner.email },
          { key: '[business_name]', type: 'my', value: this.business.name },
          { key: '[first_name]', type: 'contact', value: first_name },
          { key: '[last_name]', type: 'contact', value: last_name },
          { key: '[phone_number]', type: 'contact', value: phoneNumber },
          { key: '[email]', type: 'contact', value: email },
          { key: '[birthday]', type: 'contact', value: bday_str },
          { key: '[anniversary]', type: 'contact', value: anniv_str },
          { key: '[city]', type: 'contact', value: city },
          { key: '[note]', type: 'contact', value: note },
        ]

        this.contactFields.forEach((item) => {
          let value = '';
          if (custom_fields && custom_fields.hasOwnProperty(item.id)) {
            value = custom_fields[item.id]
          }
          this.mergeFields.push({ key: `[custom_${item.name}]`, type: 'contact', value });
        })
      }
    },

    onAddMergeField({ value, type }) {
      const cursorPos = this.$refs.newMessage.selectionStart
      let updatedMsg = this.newMessage.substring(0, cursorPos) + value + this.newMessage.substring(cursorPos, this.newMessage.length);
      this.refreshMessage(updatedMsg)
      let textValue = value
      if (this.convert)
        textValue = this.getValueFromMergeField(value)
      this.handleFocus(cursorPos + textValue.length);
    },

    loadTemplates() {
      let params = {
        provider: 'sms'
      }
      this.$store
        .dispatch("template/all", params)
        .then((res) => {
          this.templates = res;
        })
        .catch(() => { });
    },

    convertToOriginalMsg() {
      let tmp = this.newMessage + ' '
      this.mergeFields.forEach((item) => {
        if (item.value && tmp.includes(item.value)) {
          let val = item.value.replace(/[[\^$.|?*+()]/g, '\\$&');
          tmp = tmp.replace(new RegExp(val + '(\\s|\.|,)', "g"), item.key + '\$1')
        }
      });
      return tmp.trim()
    },

    onOpenModalSaveReply() {
      this.$emit("onOpenSaveReply", {
        message: this.newMessage,
        images: [...this.medias],
      });
    },

    onSelectTemplate() {
      this.activeMediaTabIndex = 0
      this.refreshMessage(this.selectedTemplate.message);
      this.removeFiles();
      this.$nextTick(() => {
        this.$refs.dropzoneUpload.manuallyAddFile([
          ...this.selectedTemplate.medias,
        ]);
        this.closeModalTemplate();
        this.$emit('done', this.selectedTemplate.medias)
      })
    },

    onOpenModalTemplate() {
      this.selectedTemplate = null;
      this.isOpenModalTemplate = true;
    },

    closeModalTemplate() {
      this.isOpenModalTemplate = false;
    },

    onOpenImageModal() {
      this.isOpenModalImage = true;
    },
    closeModalImage() {
      this.isOpenModalImage = false;
      this.$emit('done', this.medias)
    },

    handleFocus(pos = null) {
      if (this.$refs.newMessage) this.$refs.newMessage.focus();
      this.active = true;
      if (pos) {
        this.$nextTick(() => {
          const obj = this.$refs.newMessage
          obj.setSelectionRange(pos, pos);
        });
      }
    },

    handleBlur() {
      this.active = false;
    },

    handleEnterKeydown(event) {
      if (event.shiftKey===true && event.key === "Enter") {
        if (!this.newMessage.length)
          event.preventDefault();
        return;
      } else {
        event.preventDefault();
        if (this.showSendButton && !this.disabled && !this.loading && this.canMessage) {
          if (event && event.target)
            event.target.blur();
          this.send();
        }
        return;
      }
    },

    addEmoji(emoji) {
      if (this.newMessage.length + emoji.length <= this.maxLength) {
        this.refreshMessage(this.newMessage + emoji);
      }
    },

    handleEmojiPicked(emoji) {
      this.addEmoji(emoji);
    },

    checkBadword() {
      let payload = {
        content: this.newMessage
      }
      return this.$store.dispatch('auth/checkBadword', payload)
        .then((res) => {
          return Promise.resolve(res.value);
        }).catch((e) => {
          return Promise.resolve(false);
        })
    },

    async send() {
      if (this.canMessage) {
        let hasBadword = await this.checkBadword()
        if (!hasBadword) {
          this.$emit("send", {
            type: 'sms',
            images: [...this.medias]
          });
        }
      } else {
        Vue.$toast.open({
          message: "Message is required!",
          type: "default",
        });
      }
    },

    sendingFile(file, xhr, formData) { },

    removeFiles() {
      if (this.$refs.dropzoneUpload)
        this.$refs.dropzoneUpload.removeAllFiles();
      if (this.$refs.imageGallery)
        this.$refs.imageGallery.reset();
    },

    dropzoneUpdated(data) {
      const { medias } = data;
      this.medias = medias;
    },

    onSendICS() {
      this.$emit("onSendICS", {});
    },

    handleOpenModalShortLink() {
      this.shortLink = {}
      this.isOpenModalShortLink = true
    },

    handleAddShortLink() {
      this.isOpenModalShortLink = false

      this.newMessage += ` [track][${this.shortLink.href}]`
    },

    async handleOpenTest() {
      let hasBadword = await this.checkBadword()
      if (!hasBadword) {
        this.isShowTestModal = true
      }
    },

    handleTest() {
      this.isLoadingTest = true
      let params = {
        install: this.selectedInstall,
        number: this.testCredential,
        content: this.newMessage,
        images: this.medias,
      }

      this.$store.dispatch('automation/testSms', params).then((res) => {
        this.isShowTestModal = false
        this.isLoadingTest = false
        this.testCredential = null
      })
        .catch(() => {
          this.isLoadingTest = false
        })
    }
  },
};
</script>

<style lang="scss" scoped>
.schedule-icon {
  .st0 {
    fill: #fff;
    stroke: #333;
    stroke-width: 2;
  }

  .st1 {
    fill: #FFFFFF;
    stroke: #333;
    stroke-width: 2;
  }

  .st2 {
    fill: #fff;
    stroke: #333;
    stroke-width: 2;
  }

  .st3 {
    fill: #fff;
    stroke: #333;
    stroke-width: 2;
  }

  .st4 {
    fill: none;
    stroke: #333;
    stroke-width: 2;
    stroke-linecap: square;
    stroke-miterlimit: 10;
  }

  .st5 {
    fill: none;
    stroke: #333;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }

  .st6 {
    fill: none;
    stroke: #333;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st7 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #fff;
  }

  .st8 {
    fill: none;
    stroke: #333;
    stroke-width: 5;
    stroke-linecap: square;
    stroke-miterlimit: 10;
  }

  .st9 {
    fill: none;
    stroke: #333;
    stroke-width: 3;
    stroke-linecap: square;
    stroke-miterlimit: 10;
  }

  .st10 {
    fill: none;
    stroke: #333;
    stroke-width: 3;
    stroke-miterlimit: 10;
  }

  .st11 {
    fill: none;
    stroke: #333;
    stroke-width: 4;
    stroke-miterlimit: 10;
  }

  .st12 {
    fill: none;
    stroke: #333;
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st13 {
    fill: none;
    stroke: #333;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }

  .st14 {
    fill: none;
    stroke: #333;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }

  .st15 {
    fill: none;
    stroke: #333;
    stroke-width: 3;
    stroke-miterlimit: 10;
  }

  .st16 {
    fill: none;
    stroke: #333;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }

  .st17 {
    fill: none;
    stroke: #333;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }

  .st18 {
    fill: none;
    stroke: #333;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }

  .st19 {
    fill: none;
    stroke: #333;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }

  .st20 {
    fill: none;
    stroke: #333;
    stroke-width: 12.605;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }

  .st21 {
    fill: none;
    stroke: #333;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st22 {
    fill: none;
    stroke: #333;
    stroke-width: 2;
    stroke-linecap: square;
    stroke-miterlimit: 10;
  }

  .st23 {
    fill: none;
    stroke: #333;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st24 {
    fill: none;
    stroke: #333;
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st25 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #fff;
  }

  .st26 {
    fill: none;
    stroke: #333;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }

  .st27 {
    fill: none;
    stroke: #333;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke-dasharray: 2, 2;
  }

  &.active {
    .st0 {
      fill: #D9DCE1;
      stroke: none;
    }

    .st1 {
      fill: #FFFFFF;
      stroke: none;
    }

    .st2 {
      fill: #56AAFF;
      stroke: none;
    }

    .st3 {
      fill: #0478ED;
      stroke: none;
    }

    .st4 {
      fill: none;
      stroke: #0478ED;
      stroke-width: 2;
      stroke-linecap: square;
      stroke-miterlimit: 10;
    }

    .st5 {
      fill: none;
      stroke: #D9DCE1;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-miterlimit: 10;
    }

    .st6 {
      fill: none;
      stroke: #FFFFFF;
      stroke-width: 3;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }

    .st7 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #0478ED;
    }

    .st8 {
      fill: none;
      stroke: #FFFFFF;
      stroke-width: 5;
      stroke-linecap: square;
      stroke-miterlimit: 10;
    }

    .st9 {
      fill: none;
      stroke: #0478ED;
      stroke-width: 3;
      stroke-linecap: square;
      stroke-miterlimit: 10;
    }

    .st10 {
      fill: none;
      stroke: #0478ED;
      stroke-width: 3;
      stroke-miterlimit: 10;
    }

    .st11 {
      fill: none;
      stroke: #D9DCE1;
      stroke-width: 4;
      stroke-miterlimit: 10;
    }

    .st12 {
      fill: none;
      stroke: #D9DCE1;
      stroke-width: 2;
      stroke-miterlimit: 10;
    }

    .st13 {
      fill: none;
      stroke: #56AAFF;
      stroke-width: 3;
      stroke-linecap: round;
      stroke-miterlimit: 10;
    }

    .st14 {
      fill: none;
      stroke: #56AAFF;
      stroke-width: 4;
      stroke-linecap: round;
      stroke-miterlimit: 10;
    }

    .st15 {
      fill: none;
      stroke: #D9DCE1;
      stroke-width: 3;
      stroke-miterlimit: 10;
    }

    .st16 {
      fill: none;
      stroke: #56AAFF;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-miterlimit: 10;
    }

    .st17 {
      fill: none;
      stroke: #0478ED;
      stroke-width: 3;
      stroke-linecap: round;
      stroke-miterlimit: 10;
    }

    .st18 {
      fill: none;
      stroke: #56AAFF;
      stroke-linecap: round;
      stroke-miterlimit: 10;
    }

    .st19 {
      fill: none;
      stroke: #0478ED;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-miterlimit: 10;
    }

    .st20 {
      fill: none;
      stroke: #D9DCE1;
      stroke-width: 12.605;
      stroke-linecap: round;
      stroke-miterlimit: 10;
    }

    .st21 {
      fill: none;
      stroke: #FFFFFF;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }

    .st22 {
      fill: none;
      stroke: #FFFFFF;
      stroke-width: 2;
      stroke-linecap: square;
      stroke-miterlimit: 10;
    }

    .st23 {
      fill: none;
      stroke: #FFFFFF;
      stroke-width: 4;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }

    .st24 {
      fill: none;
      stroke: #FFFFFF;
      stroke-width: 2;
      stroke-miterlimit: 10;
    }

    .st25 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #56AAFF;
    }

    .st26 {
      fill: none;
      stroke: #0478ED;
      stroke-width: 4;
      stroke-linecap: round;
      stroke-miterlimit: 10;
    }

    .st27 {
      fill: none;
      stroke: #0478ED;
      stroke-width: 2;
      stroke-miterlimit: 10;
      stroke-dasharray: 2, 2;
    }
  }
}

.mergefield-wrapper {
  padding: 5px 0px;

  .mergefield-tags {
    cursor: pointer;
    font-size: 12px;
  }
}

.textarea-emoji-picker {
  position: relative;
  margin: 0 auto;
}

.emoji-trigger {
  cursor: pointer;

  .fa {
    color: #808080;
    vertical-align: middle;

    &:hover {
      polyline {
        color: darken($rb-blue, 15%);
      }
    }
  }

  svg {
    width: 24px;
    height: 24px;
  }

  path {
    transition: 0.1s all;
  }

  .path-style {
    fill: none;
    stroke: #333;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  &.merge-filed-icon {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  &.img-icon {
    svg {
      width: 22px;
      height: 22px;
    }
  }

  &.save-reply-icon,
  &.schedule-icon {
    svg {
      width: 28px;
      height: 28px;
    }
  }

  &.fill {
    &.has-medias {

      path,
      circle,
      line,
      rect,
      polyline {
        fill: $rb-blue;
      }
    }

    &:hover {

      path,
      circle,
      line,
      rect,
      polyline {
        fill: darken($rb-blue, 15%);
      }
    }

    &.triggered {

      path,
      circle,
      line,
      rect,
      polyline {
        fill: darken(#fec84a, 15%);
      }
    }
  }

  &.stroke {
    &.has-medias {

      path,
      circle,
      line,
      rect,
      polyline {
        stroke: $rb-blue;
      }
    }

    &:hover {

      path,
      circle,
      line,
      rect,
      polyline {
        stroke: darken($rb-blue, 15%);
      }
    }

    &.triggered {

      path,
      circle,
      line,
      rect,
      polyline {
        stroke: darken(#fec84a, 15%);
      }
    }
  }
}
</style>
<style lang="scss">
.image-tabs.tabs {
  .tab-pane {
    padding: 3px;
  }
}

.emoji-picker .emoji-dropdown {
  z-index: 1000;
}

.emoji-picker .emoji-invoker .invoker-icon {
  font-size: inherit;

  .show {
    font-size: inherit;
  }

  svg {
    max-height: 24px !important;
  }
}

.textarea-emoji-picker {
  border: 1px solid #dee2e6;

  &.has-hover {

    &.active,
    &:hover {
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    }
  }

  &.error {
    border-color: #e34a4a;
  }

  .send-btn {
    border-radius: 0 !important;
  }

  textarea.newMessage {
    display: block;
    text-decoration: none;
    outline: none;
    width: 100%;
    border: none;
    padding: 5px 10px 0;
  }

  .form-group {
    margin-bottom: 0;

    textarea {
      border: none;
      color: #333333;
    }

    .bar {
      display: none;
    }
  }

  .message-compose-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    height: 36px;

    ul.icon-ul {
      list-style: none;
      margin: 0;
      padding: 0;
      padding-left: 7px;

      >li {
        position: relative;
        display: inline-block;
        padding: 0 5px;

        >span.icon {
          font-size: 21px;
          padding: 0 5px;
          cursor: pointer;
          &:hover .fa{
            color: $rb-blue;
          }
        }

        >span.badge {
          min-width: 1.2rem;
          border-radius: 50%;
          position: absolute;
          right: 0px;
          top: 0px;
        }
      }
    }
  }
}

.badword-preview {
  background-color: #efefef;
  padding: 10px;
}

.custom-label {
  .tooltip-icon {
    font-size: 16px;
    display: inline-block;
    margin-left: 5px;
    margin-top: calc(4px - 8px);
  }
}</style>
